<template>
    <div class="historial-traslados-stock bg-white p-3 br-12 my-3">
        <p class="f-18 fr-medium text-general">Historial de traslados del stock</p>
        <div class="row">
            <div class="col-12">
                <el-table :data="historialStock" height="450" style="width: 100%">
                    <el-table-column prop="fecha_entrega" label="Fecha" sortable width="150" />
                    <el-table-column prop="id_admin_pedido" label="Pedido" sortable width="120" />
                    <el-table-column prop="cliente_nombre" label="Cliente" sortable width="180" />
                    <el-table-column prop="cliente_direccion" label="Direccion" sortable width="220" />
                    <el-table-column prop="nombre" label="Producto" sortable min-width="150" />
                    <el-table-column prop="cantidad" label="Unidades" sortable min-width="120">
                        <template slot-scope="scope">
                            <div>
                                <p v-if="!scope.row.solicitudes"> {{ redondearNumero(scope.row.cantidad) }}</p>
                                <p v-else> {{ redondearNumero(calcularCantidades(scope.row.solicitudes)) }}</p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="nombre_cedis" label="Cedis" sortable min-width="150" />
                    <el-table-column prop="estado" label="Acción" sortable width="130">
                        <template slot-scope="scope">
                            <div :class="`${estilosDatosAccion(scope.row.estado_destino, scope.row.id_cedis_origen)} action wf-107px rounded-pill d-middle-center h-26px`" >
                                <p class="f-14 ucfirst">
                                    {{ estadoAccion(scope.row.estado_destino, scope.row.id_cedis_origen) }}
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="Unidades entregadas y recibidas" sortable min-width="600">
                        <template slot-scope="scope">
                            <div v-if="scope.row.solicitudes" class="d-flex entregados-recibidos">
                                <div v-for="(data, index) in scope.row.solicitudes" :key="index" class="d-flex mx-3">
                                    <div class="asd">
                                        <p class="f-16 text-general tres-puntos">{{ data.nombre_cedis }} </p>
                                        <div class="d-middle text-general">
                                            <div class="d-middle">
                                                <i class="icon-format-list-checks f-19 mr-1" />
                                                <p class="f-17 fr-light"> {{ redondearNumero(data.cantidad) }} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-middle text-general ml-3">
                                        <img :src="data.img" alt="" class="wh-25 rounded-circle" />
                                        <div class="tres-puntos f-14 ml-2 lh-19">
                                            <p class="tres-puntos text-general tres-puntos">{{ data.user_recibio }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="scope.row.id_cedis_origen == idCedi" class="d-flex entregados-recibidos">
                                <div class="d-flex mx-3">
                                    <div>
                                        <p class="f-16 text-general tres-puntos">{{ scope.row.nombre_cedis }} </p>
                                        <div class="d-middle text-general">
                                            <i class="icon-format-list-checks f-19 mr-1" />
                                            <p class="f-17 fr-light"> {{ redondearNumero(scope.row.cantidad) }} </p>
                                        </div>
                                    </div>
                                    <div class="d-middle text-general ml-3">
                                        <img :src="scope.row.img" alt="" class="wh-25 rounded-circle" />
                                        <div class="tres-puntos f-14 ml-2 lh-19">
                                            <p class="tres-puntos text-general tres-puntos">{{ scope.row.user_recibio }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column> -->
                </el-table>
                <div class="col f-14 text-general2 f-500 cr-pointer text-right" @click="getHistorialTraslados">
                    Ver mas...
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Traslados from '~/services/cedis/supercedis_traslados'

export default {
    data(){
        return {
            idCedi: parseInt(this.$route.params.id_cedis),
            historialStock: [],
            page: 0,
            datosAccion: {
                0: 'Confirmado',
                1: 'Confirmado',
                2: 'Rechazado',
            },
            iconDatosAccion: {
                0: 'icon-flecha-orden',
                1: 'icon-down',
                2: 'icon-cancel',
            },
            datosAccionEnviados: {
                0: 'entregado',
                1: 'recibido',
                2: 'cancelado',
            },
        }
    },
    mounted(){
        this.getHistorialTraslados()
    },
    methods: {
        estadoAccion(estado, id){
            // if (id == this.idCedi) return this.datosAccion[0];
            return this.datosAccion[estado]
        },
        estilosDatosAccion(estado, id){
            // if (id == this.idCedi) return this.datosAccion[0];
            return this.datosAccion[estado]
        },
        async getHistorialTraslados(){
            this.page += 1;
            const params = { page: this.page }
            const { data } = await Traslados.getHistoriaslTraslado(this.idCedi, params);
            // this.historialStock = data.data;
            this.historialStock = this.historialStock.concat(data.data.data);

        },
        calcularCantidades(item){
            let sum = 0
            item.forEach(element => {
                if(element.cantidad != null) sum += parseInt(element.cantidad);
            });
            return sum;
        },
    }
}
</script>
<style lang="scss">
.historial-traslados-stock {
	.action {
		background: #F6F9FB;
		border: 1px solid #DBDBDB;
		font-size: 14px;

		&.Confirmado {
            background-color: #28D07B !important;
			color: #fff !important;
		}

		&.entregado {
			color: #FF1720 !important;
		}

		&.Rechazado {
			background-color: #FF1720 !important;
			color: #fff !important;
			border: 1px solid #FF1720;
		}
	}

	.entregados-recibidos {
		.icon-format-list-checks::before {
			margin-left: 0px !important;
		}
	}
}
</style>
